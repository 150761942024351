// import React, { useEffect } from "react";
// import { Box, Typography, Button, Link } from "@mui/material";
// import { Link as RouterLink } from "react-router-dom";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import PinterestIcon from "@mui/icons-material/Pinterest";
// import CloseIcon from "@mui/icons-material/Close";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import CallIcon from "@mui/icons-material/Call";
// import MailIcon from "@mui/icons-material/Mail";
// import ShareLocationIcon from "@mui/icons-material/ShareLocation";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import XIcon from "@mui/icons-material/X";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";

// export const Offcanvas = ({ onClose, isOpen }) => {
//   const handleMenuItemClick = () => {
//     onClose();
//   };

//   return (
//     <Box className={`offcanvas__info ${isOpen ? "info-open" : ""}`}>
//       <Box className="offcanvas__overlay" onClick={onClose} />
//       <Box className="fix-area offcanvas-content">
//         <Box className="offcanvas__wrapper">
//           <Box className="offcanvas__content"  sx={{background: "blue"}}>
//             <Box className="offcanvas__top mb-5 d-flex justify-content-between align-items-center" >
//               <Box className="offcanvas__logo">
//                 <RouterLink to="/">
//                   <img
//                     src={require("../assets/images/logo/logo_whitebg.png")}
//                     alt="site logo"
//                     width="50%"
//                   />
//                 </RouterLink>
//               </Box>
//               <Box className="offcanvas__close">
//                 <Button onClick={onClose}>
//                   <CloseIcon
//                     sx={{
//                       color: "#fff",
//                       fontSize: 30,
//                     }}
//                     className="me-4"
//                   />
//                 </Button>
//               </Box>
//             </Box>
//             <Typography
//               variant="body1"
//               className="offcanvas__content text"
//               sx={{
//                 fontSize: "18px",
//                 color: "#010156",
//                 color: "black",
//                 fontWeight: 200,
//                 fontFamily: "montesserat",
//               }}
//             >
//               We would love to hear from you!
//             </Typography>
//             <Box className="mobile-menu fix mb-3"></Box>
//             <Box className="offcanvas__contact">
//               <Typography
//                 variant="h4"
//                 fontSize="x-large"
//                 sx={{
//                   marginTop: "-14px",
//                   fontWeight: 600,
//                 }}
//               >
//                 Contact Info
//               </Typography>
//               <ul>
//                 <li className="d-flex align-items-center">
//                   <Box className="offcanvas__contact-icon">
//                     <ShareLocationIcon
//                       sx={{
//                         fontSize: 18,
//                         color: "#010156",
//                         marginLeft: "-14px",
//                         marginTop: "-5px",
//                       }}
//                     />
//                   </Box>
//                   <Box className="offcanvas__contact-text">
//                     <Link
//                       href=""
//                       className="text-black"
//                       style={{ fontSize: "15px" }}
//                     >
//                       177B Sinari Daranijo Street, VI, Lagos
//                     </Link>
//                   </Box>
//                 </li>
//                 <li className="d-flex align-items-center">
//                   <Box className="offcanvas__contact-icon mr-15">
//                     <MailIcon
//                       sx={{
//                         fontSize: 18,
//                         color: "#010156",
//                         marginLeft: "-14px",
//                         marginTop: "-5px",
//                       }}
//                     />
//                   </Box>
//                   <Box className="offcanvas__contact-text">
//                     <Link
//                       href="mailto:info@linkorgnet.com.ng"
//                       className="text-black"
//                       style={{ fontSize: "15px", textTransform: "lowercase" }}
//                     >
//                       info@linkorgnet.com.ng
//                     </Link>
//                   </Box>
//                 </li>
//                 <li className="d-flex align-items-center">
//                   <Box className="offcanvas__contact-icon mr-15">
//                     <AccessTimeIcon
//                       sx={{
//                         fontSize: 18,
//                         color: "#010156",
//                         marginLeft: "-14px",
//                         marginTop: "-5px",
//                       }}
//                     />
//                   </Box>
//                   <Box className="offcanvas__contact-text">
//                     <Link
//                       href="#"
//                       className="text-black"
//                       style={{ fontSize: "15px" }}
//                     >
//                       Mon-Friday, 08am - 05pm
//                     </Link>
//                   </Box>
//                 </li>
//                 <li className="d-flex align-items-center">
//                   <Box className="offcanvas__contact-icon mr-15">
//                     <CallIcon
//                       sx={{
//                         fontSize: 18,
//                         color: "#010156",
//                         marginLeft: "-14px",
//                         marginTop: "-5px",
//                       }}
//                     />
//                   </Box>
//                   <Box className="offcanvas__contact-text">
//                     <Link to="tel:+2349160005465)" style={{ fontSize: "15px" }}>
//                       +234 916 000 LINK (5465)
//                     </Link>
//                     <br />
//                     <Link
//                       href="tel:+2349041423631"
//                       className="text-black"
//                       style={{ fontSize: "15px" }}
//                     >
//                       +234 904 142 3631
//                     </Link>
//                   </Box>
//                 </li>
//               </ul>
//               <Box className="header-button mt-4">
//                 <RouterLink
//                   to="/contact"
//                   onClick={handleMenuItemClick}
//                   className="theme-btn text-center"
//                 >
//                   Contact Us
//                   <ChevronRightIcon />
//                 </RouterLink>
//               </Box>
//               <Box className="social-icon d-flex align-items-center">
//                 <a
//                   href="https://www.facebook.com/profile.php?id=61568155119100"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <FacebookIcon sx={{ fontSize: 20, color: "#000" }} />
//                 </a>
//                 <a
//                   href="https://instagram.com/linkorgnet"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <InstagramIcon sx={{ fontSize: 20, color: "#000" }} />
//                 </a>
//                 <a
//                   href="https://www.x.com/linkorgnet"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <XIcon sx={{ fontSize: 20, color: "#000" }} />
//                 </a>
//                 <a
//                   href="https://www.linkedin.com/company/linkorgnet"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <LinkedInIcon sx={{ fontSize: 20, color: "#000" }} />
//                 </a>
//               </Box>
//               {/* <Box className="mobile-menu d-md-none offcanvas__info offcanvas__wrapper"> */}
//               <Box
//                 className="mobile-menu offcanvas__info offcanvas__wrapper"
//                 sx={{
//                   display: {
//                     xs: "block",
//                     md: "none",
//                   },
//                   background: "red",
//                   zIndex: 10000,
//                 }}

//               >
//                 <ul className="offcanvas__content ul">
//                   <li>
//                     <RouterLink
//                       to="/"
//                       className="blutext"
//                       style={{ fontSize: "15px" }}
//                       onClick={handleMenuItemClick}
//                     >
//                       Home
//                     </RouterLink>
//                   </li>
//                   <li>
//                     <RouterLink
//                       to="/about"
//                       className="blutext"
//                       style={{ fontSize: "15px" }}
//                       onClick={handleMenuItemClick}
//                     >
//                       About
//                     </RouterLink>
//                   </li>
//                   <li>
//                     <RouterLink
//                       to="/faqs"
//                       className="blutext"
//                       style={{ fontSize: "15px" }}
//                       onClick={handleMenuItemClick}
//                     >
//                       FAQs
//                     </RouterLink>
//                   </li>
//                   <li>
//                     <RouterLink
//                       to="/services"
//                       className="blutext"
//                       style={{ fontSize: "15px" }}
//                       onClick={handleMenuItemClick}
//                     >
//                       Services
//                     </RouterLink>
//                   </li>
//                   <li>
//                     <RouterLink
//                       to="/contact"
//                       className="blutext"
//                       style={{ fontSize: "15px" }}
//                       onClick={handleMenuItemClick}
//                     >
//                       Contact
//                     </RouterLink>
//                   </li>
//                 </ul>
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };


import React, { useState } from "react";
import { Drawer, IconButton, Box, Typography, Link, Divider, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";

export const Offcanvas = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (state) => () => {
    setOpen(state);
  };

  return (
    <>
      <IconButton onClick={toggleDrawer(true)} sx={{ color: "white", display: { xs: "block", md: "none" } }}>
        <MenuIcon fontSize="large" />
      </IconButton>
      
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250, p: 2 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" sx={{ fontFamily: "Montserrat, sans-serif" }}>Menu</Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          
          <Divider sx={{ my: 2 }} />

          {/* Contact Info */}
          <Stack spacing={1}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>Contact Us:</Typography>
            <Link href="tel:+2349160005465" underline="none" sx={{ display: "flex", alignItems: "center", color: "black" }}>
              <CallIcon sx={{ mr: 1 }} /> +234 916 000 5465
            </Link>
            <Link href="mailto:info@linkorgnet.com" underline="none" sx={{ display: "flex", alignItems: "center", color: "black" }}>
              <EmailIcon sx={{ mr: 1 }} /> info@linkorgnet.com
            </Link>
          </Stack>
          
          <Divider sx={{ my: 2 }} />

          {/* Social Media Links */}
          <Stack direction="row" spacing={2} justifyContent="center">
            <Link href="#" color="inherit">
              <FacebookIcon />
            </Link>
            <Link href="#" color="inherit">
              <TwitterIcon />
            </Link>
            <Link href="#" color="inherit">
              <LinkedInIcon />
            </Link>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

