import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import axios from "axios";
import { TextField, Button, Box, CircularProgress } from "@mui/material";
import { toast } from "material-react-toastify";
import "../../ReactToastify.css";
import { useNavigate, useSearchParams } from "react-router-dom";

const SendMail = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const nameParam = searchParams.get("name") || "";
  const emailParam = searchParams.get("email") || "";

  const [formData, setFormData] = useState({
    name: nameParam,
    email: emailParam,
    subject: "",
    message: "",
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      name: nameParam,
      email: emailParam,
    }));
  }, [nameParam, emailParam]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post(
        "https://linkorgnet.vercel.app/api/v1/email/send",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      toast.success("Email sent successfully!");

      setFormData({
        ...formData,
        subject: "",
        message: "",
      });

      setTimeout(() => navigate("/admin/dashboard"), 2000);
    } catch (error) {
      console.error("Error sending email:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Failed to send email. Please try again.";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout title="Send Email">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: 500,
          margin: "auto",
          marginTop: "30px",
        }}
      >
        <TextField
          label="Full Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          sx={{}}
          // disabled
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
          // disabled
        />
        <TextField
          label="Subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
        />
        <TextField
          label="Message"
          name="message"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          required
        />
        <Button
          sx={{ background: "#E65D0F", color: "#ffffff" }}
          type="submit"
          variant="contained"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Send Email"
          )}
        </Button>
      </Box>
    </Layout>
  );
};

export default SendMail;
