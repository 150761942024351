import React, { useState, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "material-react-toastify";
import "../../ReactToastify.css";
import Layout from "./Layout";

export const UpdateProfile = () => {
  const [fname, setFirstname] = useState("");
  const [lname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [updated, setUpdated] = useState(false);
  const [adminID, setAdminID] = useState("");

  useEffect(() => {
    const storedAdminID = localStorage.getItem("adminID");
    if (!storedAdminID) {
      toast.error("Admin ID not found.");
      return;
    }

    setAdminID(storedAdminID);

    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `https://linkorgnet.vercel.app/api/v1/admin/${storedAdminID}`
        );
        const { fname, lname, phone } = response.data.data;
        setFirstname(fname || "");
        setLastname(lname || "");
        setPhone(phone || "");
      } catch (error) {
        console.error("Error fetching user details:", error);
        toast.error("Failed to load user details.");
      }
    };

    fetchUserDetails();
  }, []);

  const handleUpdateProfile = async () => {
    if (!adminID) {
      toast.error("Admin ID is missing.");
      return;
    }

    try {
      const response = await axios.put(
        `https://linkorgnet.vercel.app/api/v1/admin/${adminID}`,
        { fname, lname, phone }
      );
      if (response.data.status === "success") {
        setUpdated(true);
        toast.success("Profile updated successfully!");
      } else {
        toast.error(response.data.message || "Failed to update profile.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  return (
    <Layout title="Update Profile">
      <Box sx={{ width: "100%", maxWidth: 600, margin: "auto", mt: 4 }}>
        {!updated ? (
          <>
            <TextField
              label="First Name"
              fullWidth
              value={fname}
              onChange={(e) => setFirstname(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Last Name"
              fullWidth
              value={lname}
              onChange={(e) => setLastname(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Phone Number"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button
                variant="contained"
                sx={{ background: "#010156" }}
                onClick={handleUpdateProfile}
              >
                Update Profile
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <h2>Profile Updated Successfully!</h2>
            <Link to="/admin"></Link>
          </Box>
        )}
      </Box>
    </Layout>
  );
};
