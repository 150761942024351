import React from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import borderShape from "../../assets/images/borderShape.png";
import future from "../../assets/images/future.jpg";
import techy from "../../assets/images/techy.jpg";
import hero1 from "../../assets/images/hero1.jpg";
// Import slick carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Intro = () => {
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <section className="about-section fix section-padding">
            <Box className="container">
                <Box className="about-wrapper">
                    <Box className="row">
                        <Box className="col-lg-6">
                            <Box className="about-image-items">
                                <Box className="border-shape">
                                    {/* <img src={sysImage} alt="shape-img" /> */}
                                </Box>
                                <Box
                                    className="about-image bg-cover wow fadeInLeft"
                                    data-wow-delay=".3s"
                                    style={{
                                        backgroundImage: `url(${borderShape})`,
                                    }}
                                >
                                    <Box className="wow fadeInUp" data-wow-delay=".5s">
                                        <Slider {...sliderSettings}>
                                            <Box>
                                                <img src={future} alt="about-img" style={{ width: "100%"}} />
                                            </Box>
                                            <Box>
                                                <img src={techy} alt="about-img-2" style={{ width: "100%"}} />
                                            </Box>
                                            <Box>
                                                <img src={hero1} alt="about-img-2" style={{ width: "100%"}} />
                                            </Box>
                                            {/* <Box>
                                                <img src={fieldImage} alt="about-img-2" style={{ width: "100%"}} />
                                            </Box>
                                            <Box>
                                                <img src={fieldImage} alt="about-img-2" style={{ width: "100%"}} />
                                            </Box> */}
                                        </Slider>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="col-lg-6 mt-3 mt-lg-0">
                            <Box className="about-content">
                                <Box className="section-title">
                                    <span
                                        className="wow fadeInUp"
                                        style={{ textDecoration: "none" }}
                                    >
                                        Why Choose us?
                                    </span>
                                    <Typography
                                        variant="h2"
                                        className="wow fadeInUp"
                                        data-wow-delay=".3s"
                                        sx={{
                                            fontSize: "32px",
                                            fontWeight: 300,
                                            lineHeight: "1.3",
                                            fontFamily: "Montserrat",
                                            color: "#010156",
                                        }}
                                    >
                                        Trusted Network solutions
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="body1"
                                    className="wow fadeInUp"
                                    data-wow-delay=".5s"
                                    sx={{
                                        fontFamily: "Montserrat",
                                        lineHeight: "1.5",
                                        fontWeight: 400,
                                    }}
                                    gutterBottom
                                >
                                    LinkOrg Networks, established in 2009, is a premier Internet Service Provider (ISP), delivering high-speed and reliable internet solutions to retail customers, SMEs, and enterprises. With a strong infrastructure and strategic partnerships, we provide seamless connectivity tailored to diverse business and individual needs.  
                                    <br /><br />
                                    Beyond internet services, we offer a comprehensive suite of solutions, including VoIP, enterprise VPN, secure data storage, colocation, IT and network management, and software services. Our expertise ensures that businesses stay connected, efficient, and secure in an ever-evolving digital landscape.  
                                    <br /><br />
                                    At LinkOrg Networks, we are committed to delivering top-tier communication and technology solutions designed to enhance productivity and operational excellence. Our team of skilled professionals is dedicated to providing innovative and scalable services, ensuring you stay ahead in a connected world.  
                                    <br /><br />
                                    Experience seamless connectivity and cutting-edge technology with LinkOrg Networks—your trusted partner in internet and enterprise solutions.  
                                </Typography>
                                <Box className="about-author">
                                    <Box className="">
                                        <Link to="/contact">
                                            <button
                                                className="theme-btn d-inline about-button wow fadeInUp"
                                                data-wow-delay=".8s"
                                            >
                                                Contact Us
                                                <ChevronRightIcon
                                                    sx={{
                                                        fontSize: 40,
                                                    }}
                                                    className="d-inline"
                                                />
                                            </button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </section>
    );
};
