import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E65D0F",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const SentEmail = () => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchSentEmails = async () => {
      try {
        const response = await axios.get(
          "https://linkorgnet.vercel.app/api/v1/email/sent"
        );
        if (response.data.sentEmails) {
          setEmails(response.data.sentEmails);
          setLoading(false);
          toast.success("Emails loaded successfully!");
        } else {
          throw new Error("No email data found");
        }
      } catch (err) {
        setError("Failed to fetch emails");
        setLoading(false);
        toast.error("Failed to load emails!");
      }
    };

    fetchSentEmails();
  }, []);

  const handleChangePage = (_, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  const visibleEmails = emails.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Layout title="Sent Emails">
      <Box sx={{ paddingTop: "20px" }}>
        {emails.length === 0 ? (
          <Typography>No emails found.</Typography>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S/N</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Subject</StyledTableCell>
                    <StyledTableCell>Message</StyledTableCell>
                    <StyledTableCell>Date And Time Sent</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleEmails.map((email, index) => (
                    <TableRow key={email._id}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>{email.email}</TableCell>
                      <TableCell>{email.name}</TableCell>
                      <TableCell>{email.subject}</TableCell>
                      <TableCell>{email.message}</TableCell>
                      <TableCell>
                        {new Date(email.sentAt).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={emails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};

