import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Layout from "./Layout";
import { Link } from "react-router-dom";
import axios from "axios";

export const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchProfile = useCallback(async () => {
    const adminID = localStorage.getItem("adminID");
    if (!adminID) return;
    try {
      const response = await axios.get(
        `https://linkorgnet.vercel.app/api/v1/admin/${adminID}`
      );
      setProfile(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching profile data:", error);
      setProfile(null);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#E65D0F",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  if (loading) {
    return (
      <Layout title="Profile">
        <Box sx={{ padding: "2rem", textAlign: "center" }}>Loading...</Box>
      </Layout>
    );
  }

  if (!profile) {
    return (
      <Layout title="Profile">
        <Box sx={{ padding: "2rem", textAlign: "center" }}>
          Unable to load profile data.
        </Box>
      </Layout>
    );
  }

  return (
    <Layout title="Profile">
      <Box sx={{ padding: "2rem" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Field</StyledTableCell>
                <StyledTableCell>Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>{`${profile.fname} ${profile.lname}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Role</TableCell>
                <TableCell>{profile.role || "Admin"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone</TableCell>
                <TableCell>{profile.phone || "Not provided"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{profile.email || "Not provided"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Link to="/update_profile">
            <Button variant="contained" sx={{background:"#010156"}}>
              Update Profile
            </Button>
          </Link>
        </Box>
      </Box>
    </Layout>
  );
};
