import React from "react";
import { Box } from "@mui/material";
import { Profile } from "../../components/Admin1/Profile";
const drawerWidth = 240;

const AdminUpdateProfile = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
        ml: { xs: 0, sm: `${drawerWidth}px` },
        marginTop: "70px",
      }}
    >
      <Profile />
    </Box>
  );
};

export default AdminUpdateProfile;
