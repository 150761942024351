// import React, { useState } from "react";
// import {
//   Box,
//   TextField,
//   Button,
//   Typography,
//   CircularProgress,
//   IconButton,
//   InputAdornment,
// } from "@mui/material";
// import {
//   Lock as LockIcon,
//   Visibility,
//   VisibilityOff,
// } from "@mui/icons-material";
// import { toast } from "material-react-toastify";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const Login = () => {
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//   });
//   const [loading, setLoading] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       const response = await axios.post(
//         "https://linkorgnet.vercel.app/api/v1/admin/login",
//         formData
//       );
//       toast.success("Login successful!");
//       const { token, nextStep, adminID, fname, lname, email, phone } =
//         response.data;

//       localStorage.setItem("token", token);
//       localStorage.setItem(
//         "user",
//         JSON.stringify({ adminID, fname, lname, email, phone })
//       );

//       navigate("/admin/dashboard");
//     } catch (err) {
//       toast.error("Login failed. Please check your credentials.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Box
//       sx={{
//         maxWidth: "400px",
//         margin: "auto",
//         mt: 4,
//         p: 3,
//         boxShadow: 3,
//         borderRadius: 2,
//         marginBottom: "100px",
//       }}
//     >
//       <Typography variant="h4" mb={3} textAlign="center">
//         Login
//       </Typography>
//       <form onSubmit={handleSubmit}>
//         <Box mb={2}>
//           <TextField
//             fullWidth
//             name="email"
//             label="Email"
//             variant="outlined"
//             value={formData.email}
//             sx={{
//               input: { color: "black" },
//             }}
//             onChange={handleChange}
//           />
//         </Box>
//         <Box mb={2}>
//           <TextField
//             fullWidth
//             name="password"
//             label="Password"
//             variant="outlined"
//             type={showPassword ? "text" : "password"}
//             value={formData.password}
//             onChange={handleChange}
//             sx={{
//               input: { color: "black" },
//             }}
//             InputProps={{
//               startAdornment: <LockIcon sx={{ mr: 1 }} />,
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton onClick={togglePasswordVisibility}>
//                     {showPassword ? <VisibilityOff /> : <Visibility />}
//                   </IconButton>
//                 </InputAdornment>
//               ),
//             }}
//           />
//         </Box>
//         <Button
//           type="submit"
//           variant="contained"
//           color="primary"
//           fullWidth
//           disabled={loading}
//         >
//           {loading ? <CircularProgress size={24} /> : "Login"}
//         </Button>
//       </form>
//     </Box>
//   );
// };

// export default Login;
import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
import {
  Lock as LockIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { toast } from "material-react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://linkorgnet.vercel.app/api/v1/admin/login",
        formData,
        { withCredentials: true } // Ensures cookies are sent with the request
      );

      if (response.data.message === "success") {
        toast.success("Login successful!");
        localStorage.setItem("adminID", response.data.adminID);
        localStorage.setItem("token", response.data.token);
        navigate("/admin/dashboard");
      }
    } catch (err) {
      toast.error("Login failed. Please check your credentials.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "400px",
        margin: "auto",
        mt: 4,
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        marginBottom: "100px",
      }}
    >
      <Typography variant="h4" mb={3} textAlign="center">
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            fullWidth
            name="email"
            label="Email"
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            sx={{ input: { color: "black" } }}
          />
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            name="password"
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={formData.password}
            onChange={handleChange}
            sx={{ input: { color: "black" } }}
            InputProps={{
              startAdornment: <LockIcon sx={{ mr: 1 }} />,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Login"}
        </Button>
      </form>
    </Box>
  );
};

export default Login;
