import React, { useState } from "react";
import ReactModal from "react-modal";
import {
    Box,
    Typography,
    Button,
    Grid,
    Card,
    CardMedia,
    CardContent,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from "@mui/material";
import { Link } from "react-router-dom";
import Fiber from "../../../../src/assets/images/subservice/fiber.gif";
import Estate from "../../../assets/images/subservice/fiber.jpeg";
import Int from "../../../assets/images/subservice/internet.jpg";
import Highspeed from "../../../assets/images/subservice/highspeed.gif";
import WifiIcon from "@mui/icons-material/Wifi";
import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import NetworkCellIcon from "@mui/icons-material/NetworkCell";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";  

export const EnterprisePlan = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [formData, setFormData] = useState({
        fullName: "",
        company: "",
        email: "",
        phone: "",
        contactPerson: "",
        jobTitle: "",
        address: "",
        additionalNote: "",
        referral: "",
    });

    const plans = [
        {
            title: "Fiber To Home",
            description: `Ultra-fast fiber-optic speeds for seamless online activities\n\nFeatures\n✔High-Speed Data Transfer\n✔ Increased Bandwidth\n✔ Reliable Connectivity\n✔ Future-Proof\n✔ Symmetrical Speeds\n✔ Low Latency`,
            image: Fiber,
            icon: <HomeIcon fontSize="small" sx={{ color: "#010156" }} />,
        },
        {
            title: "Fiber To Office",
            description: `A standard-compliant and decentralized cabling concept for modern office environments. Combines advantages of highly efficient fiber optic technology.\n\nFeatures\n✔ Almost no length limitations (10 km for single mode fiber)\n✔ Only one or two wiring cabinets needed\n✔ No EMI susceptibility, very high network reliability\n✔ High bandwidth per user`,
            image: Int,
            icon: <ApartmentIcon fontSize="small" sx={{ color: "#010156" }} />,
        },
        {
            title: "Estate Connect",
            description: `Connect entire residential communities with high-speed internet access.\n\nFeatures\n✔ Centralized Network\n✔ High-Speed Connectivity\n✔ Shared Infrastructure\n✔ Customizable Plans\n✔ Enhanced Security\n✔ Convenience`,
            image: Estate,
            icon: <NetworkCellIcon fontSize="small" sx={{ color: "#010156" }} />,
        },
        {
            title: "Wireless Connectivity",
            description: `Stay connected with fast, reliable wireless internet.\n\nFeatures\n✔ High Capacity for Data Transmission\n✔ Low Latency for Real-Time Services\n✔ Supports Multiple Technologies\n✔ Scalability for Expanding Networks\n✔ Ensures Reliable Mobile Connectivity`,
            image: Highspeed,
            icon: <WifiIcon fontSize="small" sx={{ color: "#010156" }} />,
        },
    ];

    const openModal = (plan) => {
        setSelectedPlan(plan);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedPlan(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const { fullName, company, email, phone, contactPerson, jobTitle, address, additionalNote, referral } = formData;
        
        const payload = {
            fullname: fullName,
            company: company,
            email: email,
            phone: phone,
            contact: contactPerson,
            contact_job: jobTitle,
            address: address,
            plan: selectedPlan?.title, 
            how: referral,
            note: additionalNote,
        };
    
        try {
            const response = await fetch("https://linkorgnet.vercel.app/api/v1/enterprise", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
    
            const result = await response.json();
    
            if (response.ok) {
                toast.success("Form submitted successfully!"); // Success toast
                closeModal();
            } else {
                toast.error(result.message || "Failed to submit the form."); // Error toast
            }
        } catch (error) {
            console.error("Error submitting the form:", error);
            toast.error("An error occurred while submitting the form."); // Error toast
        }
    };

    return (
        <Box sx={{ padding: { xs: 2, md: 4 }, backgroundColor: "#fff" }}>
            <Grid
                container
                spacing={3}
                justifyContent="center"
                sx={{
                    maxWidth: "1200px",
                    margin: "0 auto",
                }}
            >
                {plans.map((plan, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <Card
                            sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                boxShadow: 3,
                                borderRadius: 2,
                                overflow: "hidden",
                                background: "linear-gradient(135deg, #ffffff, #f9f9f9)",
                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                "&:hover": {
                                    transform: "scale(1.03)",
                                    boxShadow: "0px 10px 20px rgba(0,0,0,0.2)",
                                },
                            }}
                        >
                            <Link to="">
                                <CardMedia
                                    component="img"
                                    height="180"
                                    image={plan.image}
                                    alt={plan.title}
                                    sx={{
                                        transition: "transform 0.3s ease",
                                        "&:hover": { transform: "scale(1.1)" },
                                    }}
                                />
                            </Link>
                            <CardContent
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: 1 }}>
                                    {plan.icon}
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{
                                            color: "#010156",
                                            fontWeight: 600,
                                            fontSize: "1.1rem",
                                        }}
                                    >
                                        {plan.title}
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: "#555",
                                        whiteSpace: "pre-line",
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    {plan.description}
                                </Typography>
                                <Box sx={{ marginTop: 2, textAlign: "center" }}>
                                    <Button
                                        variant="contained"
                                        onClick={() => openModal(plan)}
                                        sx={{
                                            background: "#010156",
                                            color: "#fff",
                                            paddingX: 3,
                                            fontSize: "0.85rem",
                                            boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                                        }}
                                    >
                                        Contact Us
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxWidth: "500px",
                        maxHeight: "600px"
                    },
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                }}
                ariaHideApp={false}
            >
                <form onSubmit={handleSubmit}>
                    <Typography className="blutext" variant="h6" sx={{ textAlign: "center", marginBottom: 2 }}>
                        {selectedPlan?.title}
                    </Typography>

                    <TextField
                        label="Full Name"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Contact Person"
                        name="contactPerson"
                        value={formData.contactPerson}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Job Title of Contact Person"
                        name="jobTitle"
                        value={formData.jobTitle}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Full Address"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Enterprise Package"
                        value={selectedPlan?.title}
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        label="Additional Note"
                        name="additionalNote"
                        value={formData.additionalNote}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={3}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>How did you hear about us?</InputLabel>
                        <Select
                            name="referral"
                            value={formData.referral}
                            onChange={handleInputChange}
                            required
                        >
                            <MenuItem value="search engine">Search Engine</MenuItem>
                            <MenuItem value="social media">Social Media</MenuItem>
                            <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                            <MenuItem value="friend/family">Friend/Family</MenuItem>
                        </Select>
                    </FormControl>

                    <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                        <Button type="submit" variant="contained" className="blu">
                            Submit
                        </Button>
                        <Button onClick={closeModal} variant="outlined" color="secondary">
                            Cancel
                        </Button>
                    </Box>
                </form>
            </ReactModal>
        </Box>
    );
};
