import React, { useState, useEffect, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "material-react-toastify";
import "../../ReactToastify.css";

const Header = ({ handleDrawerToggle }) => {
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [adminName, setAdminName] = useState("Admin");
  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("adminID");
    navigate("/login");
    toast.success("Logout Successfully");
  }, [navigate]);

  const isTokenExpired = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) return true;

    try {
      const { exp } = jwtDecode(token);
      return Date.now() >= exp * 1000;
    } catch (error) {
      return true;
    }
  }, []);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      const adminID = localStorage.getItem("adminID");
      if (!adminID) return;

      try {
        const response = await axios.get(
          `https://linkorgnet.vercel.app/api/v1/admin/${adminID}`,
          { withCredentials: true }
        );

        if (response.data.data?.fname && response.data.data?.lname) {
          const { fname, lname } = response.data.data;
          setAdminName(`${fname} ${lname}`);
        }
      } catch (error) {
        console.error(
          "Error fetching admin details:",
          error.response?.data || error.message
        );
      }
    };

    fetchAdminDetails();
  }, []);

  const checkAuth = useCallback(() => {
    if (isTokenExpired()) {
      logout();
    }
  }, [isTokenExpired, logout]);

  useEffect(() => {
    checkAuth();

    const interval = setInterval(checkAuth, 60000);
    return () => clearInterval(interval);
  }, [checkAuth]);

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: "#010156",
        height: "70px",
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            sx={{ ml: 2, mt: 2, display: { xs: "none", sm: "block" } }}
          >
            <Link to="/admin/dashboard" className="header-logo">
              <img
                src={require("../../assets/images/logo/logo.png")}
                alt="Site logo"
                width="35%"
              />
            </Link>
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="body1" sx={{ mr: 2, color: "#fff" }}>
            {adminName}
          </Typography>
          <IconButton
            color="blue"
            onClick={(e) => setProfileAnchorEl(e.currentTarget)}
          >
            <Avatar
              alt="Profile Picture"
              src="/static/images/avatar/1.jpg"
              sx={{ background: "#E65D0F" }}
            />
          </IconButton>
          <Menu
            anchorEl={profileAnchorEl}
            open={Boolean(profileAnchorEl)}
            onClose={() => setProfileAnchorEl(null)}
          >
            <Link to="/admin/profile">
              <MenuItem sx={{ color: "#010156" }}>
                <AccountBoxIcon sx={{ color: "#E65D0F" }} />
                My Profile
              </MenuItem>
            </Link>

            <Link to="/update-password">
              <MenuItem sx={{ color: "#010156" }}>
                <SettingsIcon sx={{ color: "#E65D0F" }} /> Account Settings
              </MenuItem>
            </Link>
            <MenuItem sx={{ color: "#010156" }} onClick={logout}>
              <LogoutIcon sx={{ color: "#E65D0F" }} /> Sign Out
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
