import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import axios from "axios";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  MoreVert,
  Delete,
  CheckCircle,
  Cancel,
  Send,
} from "@mui/icons-material";
import { toast } from "material-react-toastify";
import { useNavigate } from "react-router-dom";
import "../../ReactToastify.css";

export const RetailList = () => {
  const [retailRows, setRetailRows] = useState([]);
  const [pageRetail, setPageRetail] = useState(0);
  const [rowsPerPageRetail, setRowsPerPageRetail] = useState(5);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const fetchRetailData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://linkorgnet.vercel.app/api/v1/retail/"
      );
      setRetailRows(response.data);
    } catch (err) {
      toast.error("Failed to fetch Retails. Please check your connection.");
      setError("Error fetching retail data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRetailData();
  }, []);

  const handleChangePageRetail = (_, newPage) => setPageRetail(newPage);
  const handleChangeRowsPerPageRetail = (event) => {
    setRowsPerPageRetail(parseInt(event.target.value, 10));
    setPageRetail(0);
  };

  const getStatusColor = (status) => {
    const statusColors = {
      pending: "#FFD700",
      resolved: "#008000",
      rejected: "#FF0000",
      working: "#FFA500",
      default: "#D3D3D3",
    };
    return statusColors[status?.toLowerCase()] || statusColors.default;
  };

  const handleMenuClick = (event, request) => {
    setAnchorEl(event.currentTarget);
    setSelectedBookingId(request._id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedBookingId(null);
  };

  const handleChangeStatus = async (status) => {
    if (!selectedBookingId) return toast.error("No retail item selected.");

    setActionLoading(true);
    try {
      await axios.put(
        `https://linkorgnet.vercel.app/api/v1/retail/requests/${selectedBookingId}/status`,
        { status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Status updated successfully!");
      fetchRetailData();
    } catch {
      toast.error("Error changing status.");
    } finally {
      setActionLoading(false);
      handleMenuClose();
    }
  };

  const handleDeleteRetail = async () => {
    if (!selectedBookingId) return toast.error("No retail item selected.");

    setActionLoading(true);
    try {
      await axios.delete(
        `https://linkorgnet.vercel.app/api/v1/retail/requests/${selectedBookingId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Retail item deleted successfully");
      fetchRetailData();
    } catch {
      toast.error("Error deleting retail item.");
    } finally {
      setActionLoading(false);
      handleMenuClose();
    }
  };

  const confirmDelete = () => setDeleteConfirmOpen(true);
  const handleDeleteConfirmed = () => {
    setDeleteConfirmOpen(false);
    handleDeleteRetail();
  };

  const handleSendEmail = () => {
    if (!selectedBookingId) return toast.error("No retail request selected.");

    const retail = retailRows.find((row) => row._id === selectedBookingId);
    if (!retail) return toast.error("Booking not found.");

    navigate(
      `/admin/send-email?email=${encodeURIComponent(
        retail.email
      )}&name=${encodeURIComponent(retail.fullname)}`
    );
    handleMenuClose();
  };

  // Paginate data
  const currentRowsRetail = retailRows.slice(
    pageRetail * rowsPerPageRetail,
    pageRetail * rowsPerPageRetail + rowsPerPageRetail
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#E65D0F",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <Layout title="Retail List">
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        {loading ? (
          <Typography variant="h6" align="center">
            Loading retail data...
          </Typography>
        ) : error ? (
          <Typography variant="h6" align="center" color="error">
            {error}
          </Typography>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S/N</StyledTableCell>
                    <StyledTableCell>Full Name</StyledTableCell>
                    {/* <StyledTableCell>Company</StyledTableCell> */}
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Phone Number</StyledTableCell>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>Plan</StyledTableCell>
                    {/* <StyledTableCell>how</StyledTableCell> */}
                    <StyledTableCell>Note</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                    <StyledTableCell>Installation</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentRowsRetail.map((row, index) => (
                    <TableRow key={row._id || index}>
                      <TableCell>
                        {pageRetail * rowsPerPageRetail + index + 1}
                      </TableCell>
                      {[
                        "fullname",
                        "email",
                        "phone",
                        "address",
                        "plan",
                        "note",
                        "price",
                        "installation",
                      ].map((field) => (
                        <TableCell key={field}>{row[field]}</TableCell>
                      ))}
                      <TableCell>
                        <Box
                          sx={{
                            backgroundColor: getStatusColor(row.status),
                            color: "#fff",
                            textAlign: "center",
                            borderRadius: "4px",
                            p: "4px",
                            fontWeight: "bold",
                          }}
                        >
                          {row.status}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(event) => handleMenuClick(event, row)}
                          disabled={actionLoading}
                        >
                          <MoreVert />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={retailRows.length}
              rowsPerPage={rowsPerPageRetail}
              page={pageRetail}
              onPageChange={handleChangePageRetail}
              onRowsPerPageChange={handleChangeRowsPerPageRetail}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {actionLoading ? (
                <MenuItem disabled>
                  <CircularProgress size={20} sx={{ mr: 2 }} /> Processing...
                </MenuItem>
              ) : (
                [
                  <MenuItem
                    key="working"
                    onClick={() => handleChangeStatus("working")}
                  >
                    <CheckCircle sx={{ mr: 2 }} /> Mark as Working
                  </MenuItem>,
                  <MenuItem
                    key="resolved"
                    onClick={() => handleChangeStatus("resolved")}
                  >
                    <CheckCircle sx={{ mr: 2 }} /> Mark as Resolved
                  </MenuItem>,
                  <MenuItem
                    key="rejected"
                    onClick={() => handleChangeStatus("rejected")}
                  >
                    <Cancel sx={{ mr: 2 }} /> Reject Booking
                  </MenuItem>,
                  <MenuItem key="delete" onClick={confirmDelete}>
                    <Delete sx={{ mr: 2 }} /> Delete Booking
                  </MenuItem>,
                  <MenuItem key="send-email" onClick={handleSendEmail}>
                    <Send sx={{ mr: 2 }} /> Send Mail
                  </MenuItem>,
                ]
              )}
            </Menu>

            <Dialog
              open={deleteConfirmOpen}
              onClose={() => setDeleteConfirmOpen(false)}
            >
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this booking?
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteConfirmOpen(false)}>
                  Cancel
                </Button>
                <Button onClick={handleDeleteConfirmed} color="error">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
    </Layout>
  );
};
