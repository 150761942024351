import React, { useState } from "react";
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  GridView,
  Person,
  Email,
  Send,
  History,
  Inbox,
  Book,
  LocalOffer,
  ShoppingCart,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
  const [openEmail, setOpenEmail] = useState(false);

  const handleClickEmail = () => setOpenEmail(!openEmail);

  const drawerContent = (
    <Box sx={{ marginTop: "70px", paddingTop: "10px" }}>
      <List>
        {/* Home (Dashboard) */}
        <ListItem button component={Link} to="/admin/dashboard">
          <ListItemIcon>
            <GridView sx={{ color: "#E65D0F" }} />
          </ListItemIcon >
          <ListItemText primary="Dashboard" sx={{ color: "#010156", fontWeight:"800"}} />
        </ListItem>

        {/* Contact List */}
        <ListItem button component={Link} to="/admin/contacts">
          <ListItemIcon>
            <Person sx={{ color: "#E65D0F" }}/>
          </ListItemIcon>
          <ListItemText primary="Contact List" sx={{ color: "#010156", fontWeight:"800"}}/>
        </ListItem>

        {/* Booking List */}
        <ListItem button component={Link} to="/admin/bookings">
          <ListItemIcon>
            <Book sx={{ color: "#E65D0F" }}/>
          </ListItemIcon>
          <ListItemText primary="Booking List" sx={{ color: "#010156", fontWeight:"800"}}/>
        </ListItem>

        {/* Enquiry List */}
        <ListItem button component={Link} to="/admin/enquiries">
          <ListItemIcon>
            <LocalOffer sx={{ color: "#E65D0F" }}/>
          </ListItemIcon>
          <ListItemText primary="Enquiry List" sx={{ color: "#010156", fontWeight:"800"}}/>
        </ListItem>

        {/* Retail List */}
        <ListItem button component={Link} to="/admin/retail-list">
          <ListItemIcon>
            <ShoppingCart sx={{ color: "#E65D0F" }}/>
          </ListItemIcon>
          <ListItemText primary="Retail List" sx={{ color: "#010156", fontWeight:"800"}}/>
        </ListItem>

        {/* Enterprise List */}
        <ListItem button component={Link} to="/admin/enterprise-list">
          <ListItemIcon>
            <Person sx={{ color: "#E65D0F" }}/>
          </ListItemIcon>
          <ListItemText primary="Enterprise List" sx={{ color: "#010156", fontWeight:"800"}}/>
        </ListItem>

        {/* Email Navigation */}
        <ListItem button onClick={handleClickEmail}>
          <ListItemIcon>
            <Email sx={{ color: "#E65D0F" }}/>
          </ListItemIcon>
          <ListItemText primary="Email" sx={{ color: "#010156", fontWeight:"800"}}/>
          {openEmail ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openEmail} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Send Email */}
            <ListItem button component={Link} to="/admin/send-email">
              <ListItemIcon>
                <Send sx={{ color: "#E65D0F" }}/>
              </ListItemIcon>
              <ListItemText primary="Send Email" sx={{ color: "#010156", fontWeight:"800"}}/>
            </ListItem>

            {/* Sent Email History */}
            <ListItem button component={Link} to="/admin/sent-mails">
              <ListItemIcon>
                <History sx={{ color: "#E65D0F" }}/>
              </ListItemIcon>
              <ListItemText primary="Sent Email History" sx={{ color: "#010156", fontWeight:"800"}}/>
            </ListItem>

            {/* Email Inbox */}
            <ListItem button component={Link} to="/admin/email-inbox">
              <ListItemIcon>
                <Inbox sx={{ color: "#E65D0F" }}/>
              </ListItemIcon>
              <ListItemText primary="Email Inbox" sx={{ color: "#010156", fontWeight:"800"}}/>
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Box>
  );

  return (
    <>
      {/* Drawer for small screens */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>

      {/* Permanent drawer for large screens */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { width: drawerWidth },
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Sidebar;
