import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({ title, children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Sidebar */}
      <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />

      <Box sx={{ flexGrow: 1 }}>
        {/* Header */}
        <Header handleDrawerToggle={handleDrawerToggle} />

        {/* Main Content */}
        <Box
          sx={{
            p: 1,
            backgroundColor: "#EDF5F8",
            minHeight: "calc(100vh - 64px)",
            minWidth: "calc(100% - 240px)"
          }}
        >
          {/* Page Title */}
          {title && (
            <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold", color: "#010156" }}>
              {title}
            </Typography>
          )}

          {children} {/* Render the child components dynamically */}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
