import React from "react";
import { Navbar } from "./Navbar";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "animate.css";
import TawkToChat from './components/TawkTo';
import ConsentBanner from "./ConsentBanner";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import VoipPage from "./pages/servicesDetails/VoipPage";
import SatellitePage from "./pages/servicesDetails/SatellitePage";
import VPNPage from "./pages/servicesDetails/VPNPage";
import ITNetworkPage from "./pages/servicesDetails/ITNetworkPage";
import StoragePage from "./pages/servicesDetails/StoragePage";
import InternetPage from "./pages/servicesDetails/InternetPage";
import CollocationPage from "./pages/servicesDetails/CollacationPage";
import SoftwarePage from "./pages/servicesDetails/SoftwarePage";
import HighSpeed from "./pages/subServices/HighSpeed";
import Fiber from "./pages/subServices/Fiber";
import EstateConnectInternet from "./pages/subServices/EstateConnect";
import Mpls3 from "./pages/subServices/Mpls3";
import Mpls2 from "./pages/subServices/Mpls2";
import LeasedLine from "./pages/subServices/LeasedLine";
import { GeneralQuestions } from "./components/FAQs/General";
import { ItQuestions } from "./components/FAQs/ItQuestion";
import { SoftwareQuestions } from "./components/FAQs/Software";
import { StorageQuestions } from "./components/FAQs/Storage";
import { InternetQuestions } from "./components/FAQs/Internet";
import { VPNQuestions } from "./components/FAQs/VPN";
import { VoipQuestions } from "./components/FAQs/Voip";
import { CollationQuestions } from "./components/FAQs/Collation";
import { VSATQuestions } from "./components/FAQs/VSAT";
import Error404 from "./pages/404";

import MaritimePage from "./pages/subServices/MaritimePage";
import TerrestrialPage from "./pages/subServices/TerrestrialPage";
import BackhaulingPage from "./pages/subServices/BackhaulingPage";
import AppSecurePage from "./pages/subServices/AppSecurePage";
import WanAndLanPage from "./pages/subServices/WanAndLanPage";
import EndSecurePage from "./pages/subServices/EndSecurePage";
import SanPage from "./pages/subServices/SanPage";
import NasPage from "./pages/subServices/NasPage";
import CloudStoragePage from "./pages/subServices/CloudStoragePage";
import VirtualServerPage from "./pages/subServices/VirtualServerPage";
import DataBackupPage from "./pages/subServices/DataBackupPage";
import CollocationSubPage from "./pages/subServices/CollocationPage";
import CustomDevelopPage from "./pages/subServices/CustomDevelopPage";
import SeemlessIntegratePage from "./pages/subServices/SeemlessIntegratePage";
import MaintenancePage from "./pages/subServices/MaintenancePage";
import { ToastContainer } from 'material-react-toastify';
import { Navigate } from "react-router-dom";
import RegisterPage from "./pages/Auth/RegisterPage";
import LoginPage from "./pages/Auth/LoginPage";
import Retail from "./pages/Internet/Retail";
import Enterprise from "./pages/Internet/Enterprise";

//Admin Pages
import Layout from "./components/Admin1/Layout"; 
import AdminDashboard from "./pages/AdminPage1/AdminDashboard";
import AdminRetailList from "./pages/AdminPage1/AdminRetailList";
import AdminEnterpriseList from "./pages/AdminPage1/AdminEnterprise";
import AdminSendMail from "./pages/AdminPage1/AdminSendEmail";
import AdminEmailInbox from "./pages/AdminPage1/AdminEmailInbox"
import AdminContactList from "./pages/AdminPage1/AdminContactList";
import AdminUpdateProfile from "./pages/AdminPage1/AdminUpdateProfile";
import AdminProfile from "./pages/AdminPage1/AdminProfile";
import AdminChangePassword from "./pages/AdminPage1/AdminChangePassword";
import AdminSentMails from "./pages/AdminPage1/AdminSentMails";
import AdminEnquiryList from "./pages/AdminPage1/AdminEnquiryList";
import AdminBookingList from "./pages/AdminPage1/AdminBookingList";


function App() {
  const location = useLocation();

  // List of admin routes (Home page Nav not included)
  const adminRoutes = ["/admin/dashboard", "/admin/contacts", "/admin/pending-contacts", "/admin/enquiries", "/admin/bookings", "/admin/retail-list", "/admin/enterprise-list", "/admin/profile", "/update_profile", "/update-password", "/admin/send-email", "/admin/sent-mails", "/admin/email-inbox"];
  
  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    return !!token; 
  };

  const PrivateRoute = ({ children }) => {
    return isAuthenticated() ? children : <Navigate to="/login" />;
  };
  return (
    <>
      <ToastContainer />
      {!adminRoutes.includes(location.pathname) && (
        <>
          <Navbar />
          <ConsentBanner />
          <TawkToChat />
        </>
      )}

      <ScrollToTop />
      <Routes>
        {/* Basic Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />

        {/* Services */}
        <Route path="/services/voip-services" element={<VoipPage />} />
        <Route path="/services/internet" element={<InternetPage />} />
        <Route path="/services/satellite" element={<SatellitePage />} />
        <Route path="/services/vpn-solutions" element={<VPNPage />} />
        <Route path="/services/it-networks" element={<ITNetworkPage />} />
        <Route path="/services/storage-solutions" element={<StoragePage />} />
        <Route path="/services/collocation-and-data-center" element={<CollocationPage />} />
        <Route path="/services/software-services" element={<SoftwarePage />} />
        <Route path="/services/internet/retail" element={<Retail />} />
        <Route path="/services/internet/enterprise" element={<Enterprise />} />

        {/* Subservices */}
        <Route path="/services/internet/highspeed" element={<HighSpeed />} />
        <Route path="/services/internet/fiber" element={<Fiber />} />
        <Route path="/services/internet/estate_connect" element={<EstateConnectInternet />} />
        <Route path="/services/vpn-solutions/mpls3" element={<Mpls3 />} />
        <Route path="/services/vpn-solutions/mpls2" element={<Mpls2 />} />
        <Route path="/services/vpn-solutions/leased" element={<LeasedLine />} />
        <Route path="/services/satellite/terrestrial" element={<TerrestrialPage />} />
        <Route path="/services/satellite/maritime" element={<MaritimePage />} />
        <Route path="/services/satellite/backhauling" element={<BackhaulingPage />} />
        <Route path="/services/it-networks/app_security" element={<AppSecurePage />} />
        <Route path="/services/it-networks/wan_lan" element={<WanAndLanPage />} />
        <Route path="/services/it-networks/endpoint" element={<EndSecurePage />} />
        <Route path="/services/storage-solutions/san" element={<SanPage />} />
        <Route path="/services/storage-solutions/nas" element={<NasPage />} />
        <Route path="/services/storage-solutions/cloud_storage" element={<CloudStoragePage />} />
        <Route path="/services/collocation-and-data-center/virtual_server" element={<VirtualServerPage />} />
        <Route path="/services/collocation-and-data-center/collocate" element={<CollocationSubPage />} />
        <Route path="/services/collocation-and-data-center/data_backup" element={<DataBackupPage />} />
        <Route path="/services/software-services/custom_develop" element={<CustomDevelopPage />} />
        <Route path="/services/software-services/seemless_integrate" element={<SeemlessIntegratePage />} />
        <Route path="/services/software-services/maintenance" element={<MaintenancePage />} />

        {/* FAQs */}
        <Route path="/faqs" element={<GeneralQuestions />} />
        <Route path="/faqs/it-networks" element={<ItQuestions />} />
        <Route path="/faqs/software-services" element={<SoftwareQuestions />} />
        <Route path="/faqs/storage-services" element={<StorageQuestions />} />
        <Route path="/faqs/internet" element={<InternetQuestions />} />
        <Route path="/faqs/voip-services" element={<VoipQuestions />} />
        <Route path="/faqs/vpn-services" element={<VPNQuestions />} />
        <Route path="/faqs/collocation-data-center" element={<CollationQuestions />} />
        <Route path="/faqs/vsat-services" element={<VSATQuestions />} />

        {/* Admin Routes (Protected) */}
        <Route path="/admin" element={<Layout />} />
        <Route path="/admin/contacts" element={<PrivateRoute><AdminContactList /></PrivateRoute>} />
        <Route path="/admin/dashboard" element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
        <Route path="/admin/enquiries" element={<PrivateRoute><AdminEnquiryList /></PrivateRoute>} />
        <Route path="/admin/bookings" element={<PrivateRoute><AdminBookingList /></PrivateRoute>} />
        <Route path="/admin/retail-list" element={<PrivateRoute><AdminRetailList /></PrivateRoute>} />
        <Route path="/admin/enterprise-list" element={<PrivateRoute><AdminEnterpriseList /></PrivateRoute>} />
        <Route path="/admin/profile" element={<PrivateRoute><AdminProfile /></PrivateRoute>} />
        <Route path="/update_profile" element={<PrivateRoute><AdminUpdateProfile /></PrivateRoute>} />
        <Route path="/update-password" element={<PrivateRoute><AdminChangePassword /></PrivateRoute>} />
        <Route path="/admin/send-email" element={<PrivateRoute><AdminSendMail /></PrivateRoute>} />
        <Route path="/admin/sent-mails" element={<PrivateRoute><AdminSentMails /></PrivateRoute>} />
        <Route path="admin/email-inbox" element={<PrivateRoute><AdminEmailInbox /></PrivateRoute>} />

        {/* Fallback route */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default App;
