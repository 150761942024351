import React, { useState, useEffect } from "react";
import { Box, Button, TextField, DialogContent, MenuItem, Select, InputLabel, FormControl, Dialog, DialogTitle } from '@mui/material';
import { Link } from "react-router-dom";



const Modal = ({ open, planName, planPrice, handleClose }) => {
    const installationFee =
        planName === "LinkOrg VIP1" || planName === "LinkOrg VIP2" ? 100000 : 50000;

    const [formData, setFormData] = useState({
        fullName: "",
        company: "",
        email: "",
        phone: "",
        address: "",
        internetPlan: planName,
        price: 0,
        howDidYouHear: "",
        additionalNote: "",
        agreeToNewsletter: false
    });

    useEffect(() => {
        const calculateTotalPrice = () => {
            const priceWithoutSymbols = planPrice ? parseFloat(planPrice.replace(/[^0-9]/g, "")) || 0 : 0;
            const totalPrice = priceWithoutSymbols + installationFee;
            const vat = totalPrice * 0.075;
            return totalPrice + vat;
        };

        setFormData((prevData) => ({
            ...prevData,
            internetPlan: planName,
            price: calculateTotalPrice(),
        }));
    }, [planName, planPrice]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            agreeToNewsletter: e.target.checked,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("https://linkorgnet.vercel.app/api/v1/retail/booking", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    fullname: formData.fullName,
                    company: formData.company,
                    email: formData.email,
                    phone: formData.phone,
                    address: formData.address,
                    plan: formData.internetPlan,
                    price: parseFloat(planPrice.replace(/[^0-9]/g, "")) || 0,
                    installation: installationFee,
                    how: formData.howDidYouHear,
                    note: formData.additionalNote,
                }),
            });

            const result = await response.json();
            if (response.ok) {
                alert("Booking Successful!");
                handleClose();
            } else {
                alert(result.message || "Something went wrong");
            }
        } catch (error) {
            console.error("Error submitting booking:", error);
            alert("Error submitting booking");
        }
    };

    if (!open) return null;

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ fontWeight: 500 }}>
                Confirm Your Plan
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Full Name"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <TextField
                        label="Company"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <TextField
                        label="Phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <TextField
                        label="Full Address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        fullWidth
                        required
                        multiline
                        rows={3}
                        margin="normal"
                    />
                    <TextField
                        label="Price (Monthly + Installation + VAT)"
                        value={`₦ ${formData.price}`}
                        fullWidth
                        InputProps={{ readOnly: true }}
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>How did you hear about us?</InputLabel>
                        <Select
                            name="howDidYouHear"
                            value={formData.howDidYouHear}
                            onChange={handleChange}
                        >
                            <MenuItem value="Search Engine">Search Engine</MenuItem>
                            <MenuItem value="Social media">Social Media</MenuItem>
                            <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                            <MenuItem value="Friend/Family">Friend/Family</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Additional Note"
                        name="additionalNote"
                        value={formData.additionalNote}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={2}
                        margin="normal"
                    />
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                        <Button type="submit" variant="contained" sx={{backgroundColor: "#010156"}}>
                            Submit
                        </Button>
                        <Button onClick={handleClose} className="blutext" variant="outlined" sx={{borderColor: "#010156"}}>
                            Close
                        </Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    );
};



export const Search = () => {
    const [planName, setPlanName] = useState('');
    const [planPrice, setPlanPrice] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const handlePlanChange = (event) => {
        const selectedPlan = event.target.value;
        let price = '';
        let name = '';
    
        switch (selectedPlan) {
            case 'express':
                price = '₦16020';
                name = 'Express';
                break;
            case 'super':
                price = '₦18500';
                name = 'Super';
                break;
            case 'gold':
                price = '₦30000';
                name = 'Gold';
                break;
            case 'linkorgvip1':
                price = '₦60000';
                name = 'LinkOrg VIP1';
                break;
            case 'linkorgvip2':
                price = '₦80000';
                name = 'LinkOrg VIP2';
                break;
            default:
                price = '';
                name = '';
        }
    
        setPlanName(name); 
        setPlanPrice(price);
    };
    
    // Handle modal open/close
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    return (
        <Box className="container-fluid blu mb-5 wow fadeIn" data-wow-delay="0.1s" style={{ padding: "35px", marginTop: "-1%" }}>
            <Box className="container">
                <Box className="row g-2">
                    <Box className="col-md-10">
                        <Box className="row g-2">
                            <Box className="col-md-4">
                                <input 
                                    type="text" 
                                    className="form-control border-0 py-3" 
                                    placeholder="Buy Retail & SME Internet Plan" />
                            </Box>
                            <Box className="col-md-4">
                                <select className="form-select border-0 py-3" onChange={handlePlanChange}>
                                    <option value="">Choose Data Plan</option>
                                    <option value="express">Express (Up to 20mbps)</option>
                                    <option value="super">Super (Up to 30mbps)</option>
                                    <option value="gold">Gold (Up to 60mbps)</option>
                                    <option value="linkorgvip1">LinkOrg VIP1 (Up to 70mbps)</option>
                                    <option value="linkorgvip2">LinkOrg VIP2 (Up to 100mbps)</option>
                                </select>
                            </Box>
                            <Box className="col-md-4">
                                <select className="form-select border-0 py-3" disabled>
                                    <option value="expressprice">{planName && `${planName} - ${planPrice} (+ installation + VAT)`}</option>
                                </select>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="col-md-2">
                        <Link
                            className="theme-btn theme-btn-3 border-0 w-100 py-3"
                            style={{ marginTop: "3px" }}
                            onClick={openModal}
                        >
                            Buy now
                        </Link>
                    </Box>
                </Box>
            </Box>

            {/* Modal for confirming the plan */}
            <Modal open={modalOpen} planName={planName} planPrice={planPrice} handleClose={closeModal} />
        </Box>
    );
};
