import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: "#E65D0F",
  color: theme.palette.common.white,
}));

export const EmailInbox = () => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchEmailInbox = async () => {
      try {
        const response = await axios.get(
          "https://linkorgnet.vercel.app/api/v1/email/inbox"
        );
        if (response.data.result) {
          setEmails(response.data.result);
          setLoading(false);
          toast.success("Email loaded successfully!");
        } else {
          throw new Error("No email data found");
        }
      } catch (err) {
        setError("Failed to fetch emails");
        setLoading(false);
        toast.error("Failed to load email!");
      }
    };

    fetchEmailInbox();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  const visibleEmails = emails.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Layout title="Emails Inbox">
      <Box sx={{ padding: "2rem" }}>
        {emails.length === 0 ? (
          <Typography>No emails found.</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>S/N</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Subject</StyledTableCell>
                  <StyledTableCell>Message</StyledTableCell>
                  <StyledTableCell>Date And Time Sent</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleEmails.map((email, index) => (
                  <TableRow key={email._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{email.from.email}</TableCell>
                    <TableCell>{email.from.name}</TableCell>
                    <TableCell>{email.subject}</TableCell>
                    <TableCell>{email.bodyPreview}</TableCell>
                    <TableCell>
                      {new Date(email.receivedDateTime).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={emails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </Box>
      <ToastContainer />
    </Layout>
  );
};
